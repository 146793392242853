import React, { useCallback, useState } from "react";
import { createRoot } from "react-dom/client";
import Preview from "./Preview";
import Wizard from "./Wizard";
import "./index.css";

function RecipeWizard() {
  const [preview, setPreview] = useState("ingredients");
  const [data, setData] = useState({
    informations: localStorage.getItem("informations") || "",
    instructions: localStorage.getItem("instructions") || "",
    ingredients: localStorage.getItem("ingredients") || "",
    backgroundCover: "" // TODO: how do I store?
  });

  const propagate = useCallback(
    (a) => setData(a),
    [setData]);

  const view = useCallback(
    (a) => setPreview(a),
    [setPreview]);

  const submit = useCallback(
    () => {
      const body = `
Ingredients:
${data.ingredients}
(end of ingredients)

Instructions:
${data.instructions}
(end of instructions)

Informations:
${data.informations}
(end of informations)

I acknowledge that I will receive emails regarding the status of this submission, and I accept 
subscribing to these email updates, having read and agreed to the terms of usage. Additionally, 
I grant the right to use the image cover I provide for this submission.

Links to Google Photos or Apple iCloud:
`;

      window.location.href = `mailto:gustasta@tewoto.com?subject=New recipe&body=` + encodeURIComponent(body);
    }, [data]);

  const restart = useCallback(
    () => {
      if (window.confirm("Erase all fields and restart from scratch?"))
        localStorage.clear();
    }, []);

  return (
    <React.Fragment>
      <div className="container">
        <section>
          <Wizard propagate={propagate} view={view} submit={submit} />
        </section>
        <section>
          <div className="smartphone">
            <div className={preview + " screen"}>
              <Preview view={preview} data={data} />
            </div>
            <div className="action" children="Continue" />
            <div className="speaker" />
            <div className="refresh" children="⟳ Restart" onClick={restart} />
          </div>
        </section>
      </div>
    </React.Fragment>
  );
}

const root = document.getElementById("gustasta.com");

createRoot(root).render(<React.StrictMode children={<RecipeWizard />} />);
