import React, { useCallback, useEffect, useState } from "react";
import "./Preview.css";

const parseIngredients = (text) => {
    const items = text.split(/[,\n]/).map(a => a.trim());
    const regex = /^(?<quantity>\d+(?:\.\d+)?[a-z]{0,3})\s+(?<name>.*)$/;

    return items
        .filter(a => a.length > 0)
        .map(a => {
            const matches = a.match(regex);

            if (matches && matches.groups) {
                const { quantity, name } = matches.groups;

                return { quantity, name };
            }

            return { quantity: "?!", name: a };
        });
}

const parseInstructions = (text) => {
    return text
        .split("\n")
        .filter(a => a.trim().length > 0)
        .map(a => {
            const dot = a.indexOf(".");
            if (dot > -1) {
                const step = {
                    step: a.slice(0, dot).trim(),
                    rest: a.slice(dot).trim()
                };

                if (step.rest.indexOf("/") > -1) {
                    const [rest, ...modifiers] = step.rest.split("/").map(b => b.trim());
                    modifiers.forEach(m => {
                        if (/^-?\d+C$/.test(m)) step.temp = m.replace("C", "°C");
                        if (/^-?\d+F$/.test(m)) step.temp = m.replace("F", "°F");
                        if (/^\d+d$/.test(m)) step.time = m.replace("d", " days");
                        if (/^1d$/.test(m)) step.time = m.replace("d", " day");
                        if (/^\d+h$/.test(m)) step.time = m.replace("h", " hours");
                        if (/^1h$/.test(m)) step.time = m.replace("h", " hour");
                        if (/^\d+m$/.test(m)) step.time = m.replace("m", " mins");
                        if (/^1m$/.test(m)) step.time = m.replace("m", " min");
                        if (/^\d+s$/.test(m)) step.time = m.replace("s", " secs");
                        if (/^1s$/.test(m)) step.time = m.replace("s", " sec");
                    });
                    step.rest = rest;
                }

                return step;
            }

            return { step: a.trim() };
        });
};

const parseInformations = (text) => {
    const lines = text.split("\n").map(a => a.trim()).filter(a => a.length > 0);
    const info = {
        title: lines[0],
        prepTime: "",
        cookTime: "",
        servings: "",
        requirements: "",
    };

    lines.forEach(a => {
        if (a.startsWith('Prep & cook time:')) {
            [info.prepTime, info.cookTime] = a.replace('Prep & cook time:', '').trim().split("&").map(a => a.trim());
        } else if (a.startsWith('No. of servings:')) {
            info.servings = a.replace('No. of servings:', '').trim();
        } else if (a.startsWith('Requirements:')) {
            info.requirements = a.replace('Requirements:', '').trim();
        }
    });

    return info;
};

export default function Preview({ view, data }) {
    switch (view) {
        case "ingredients":
            return <Ingredients data={parseIngredients(data.ingredients)} />
        case "instructions":
            return <Instructions data={parseInstructions(data.instructions)} />
        case "informations":
            return <Informations data={parseInformations(data.informations)} cover={data.backgroundCover} />
        case "thanks":
            return <ThankYou data={data} completed={data.ingredients.length > 0 && data.instructions.length > 0} />
        default:
            return <React.Fragment />
    }
}

function Ingredients({ data }) {
    if (data.length === 0) {
        return <div className="nothing" />;
    }

    return (
        <React.Fragment>
            <header children="Here are the ingredients" />
            <ul>
                {data.map((a, i) =>
                    <li key={i} children={a.name} data-quantity={a.quantity} />)}
            </ul>
        </React.Fragment>
    );
}

function Instructions({ data }) {
    const [index, setIndex] = useState(data.length === 0 ? 0 : data.length - 1);
    const [source, setSource] = useState("");

    useEffect(() => {
        if (data[index] === undefined)
            return; // should never end up here...
        fetch("/display?name=" + data[index].step)
            .then(a => a.json())
            .then(a => setSource(a.url));
    }, [data, index, setSource]);

    const prev = useCallback(() => setIndex(x => x > 0 ? x - 1 : x), [setIndex]);
    const next = useCallback(() => setIndex(x => x < data.length - 1 ? x + 1 : x), [setIndex, data]);

    if (data.length === 0) {
        return <div className="nothing" />;
    }

    return (
        <React.Fragment>
            <header>
                <span className="controls prev" onClick={prev} data-enabled={index > 0} />
                Progress {Number(index * 100 / data.length).toFixed(0)}%
                <span className="controls next" onClick={next} data-enabled={index < data.length - 1} />
            </header>
            <main key={source}>
                {source.endsWith(".mp4")
                    ? <video muted autoPlay loop children={<source src={source} type="video/mp4" />} />
                    : <img src={source} alt="" />}
            </main>
            {data[index]
                && <p>
                    <strong children={data[index].step} />
                    {data[index].rest || ""}
                    <br />
                    {data[index].temp
                        && <span className="temp modifier" children={data[index].temp} />}
                    {data[index].time
                        && <span className="time modifier" children={data[index].time} />}
                </p>}
        </React.Fragment>
    );
}

function Informations({ data: { title, requirements, prepTime, cookTime, servings }, cover }) {
    if (!cover) {
        return <div className="nothing" />;
    }

    return (
        <React.Fragment>
            <img src={cover} width="100%" alt="" />
            <div className="presentation">
                <div className="title" children={title} />
                <div className="button" children="Start" />
            </div>
            <div className="details">
                <ul>
                    <li>{servings}<span children="servings" /></li>
                    <li>{prepTime}<span children="prep time" /></li>
                    <li>{cookTime}<span children="cook time" /></li>
                </ul>
                <p>
                    <small children="Required cookware" />
                    <br />
                    {requirements}
                </p>
                <p className="placeholder">
                    <small children="Contributor's Insight" />
                    <br />
                    This area of the screen may eventually display more information, like nutritional facts, calories, user ratings, and web features, though the application cannot guarantee when these updates will happen.
                </p>
                <br /> {/* roll eyes emoji */}
            </div>
        </React.Fragment>
    );
}

function ThankYou({ completed, data }) {
    useEffect(() => {
        if (completed && window.confetti) {
            const end = Date.now() + 1000;

            (function frame() {
                window.confetti({
                    particleCount: 7,
                    angle: 60,
                    spread: 55,
                    origin: { x: 0 }
                });
                window.confetti({
                    particleCount: 7,
                    angle: 120,
                    spread: 55,
                    origin: { x: 1 }
                });

                if (Date.now() < end) requestAnimationFrame(frame);
            }());
        }
    }, [completed]);

    if (!completed) {
        return <div className="nothing" />
    }

    const ingredients = parseIngredients(data.ingredients);
    const information = parseInformations(data.informations);

    return (
        <React.Fragment>
            <header children="Here's what we found for you" />
            <div className="search" children={ingredients.map(a => a.name).join(", ")} />
            <div className="feature" style={{ "--background": "url(" + data.backgroundCover + ")" }}>
                <p>{information.title}</p>
            </div>
            <div className="feature" style={{ "--background": "url(" + data.backgroundCover + ")" }}>
                <p>{information.title}</p>
            </div>
        </React.Fragment>
    );
}
