import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./Wizard.css";

const threshold = 0.55;

export default function Formular({ propagate, view, submit }) {
    const articleRefs = useRef([]);

    useEffect(() => {
        const refs = articleRefs.current;
        const observer = new IntersectionObserver(
            (entries) => entries.forEach(a => {
                if (a.isIntersecting) view(a.target.id)
            }),
            { threshold });

        refs.forEach(a => a && observer.observe(a));

        return () => {
            refs.forEach(a => a && observer.unobserve(a));
        }
    }, [view]);

    const saveIngredients = useCallback(
        (target) => {
            localStorage.setItem("ingredients", target.textContent);
            propagate(a => ({ ...a, ingredients: target.textContent }));
        }, [propagate]);

    const saveInstructions = useCallback(
        (target) => {
            localStorage.setItem("instructions", target.textContent);
            propagate(a => ({ ...a, instructions: target.textContent }));
        }, [propagate]);

    const saveInformations = useCallback(
        (target) => {
            localStorage.setItem("informations", target.textContent);
            propagate(a => ({ ...a, informations: target.textContent }));
        }, [propagate]);

    const pushBackgroundCover = useCallback(
        (backgroundCover) => propagate(a => ({ ...a, backgroundCover })),
        [propagate]);

    return (
        <div className="formular">
            <article ref={x => articleRefs.current[0] = x} id="ingredients"
                children={<Ingredients update={saveIngredients} />} />
            <article ref={x => articleRefs.current[1] = x} id="instructions"
                children={<Instructions update={saveInstructions} />} />
            <article ref={x => articleRefs.current[2] = x} id="informations"
                children={<Informations update={saveInformations} updateCover={pushBackgroundCover} />} />
            <article ref={x => articleRefs.current[3] = x} id="thanks">
                <h2 children="almost there!" />
                <p>Hit the button to submit your recipe and finish up. We'll manually review it, which might take up to 3 business days. Don't worry — updates will be sent to your smartphone!</p>
                <p>Ready? <a href="#submit" onClick={submit}>Send the recipe</a>.</p>
            </article>
        </div>
    );
}

const defaultMeta = `The name or title of your dish goes here, on the first line

Prep & cook time: (approx. time for preparation and cooking? i.e. 45m) 
No. of servings: (how many would you get from this?) 
Requirements: (any particular set of cookware required for this dish?) 
`;

function Informations({ update, updateCover }) {
    const initialText = useMemo(() => localStorage.getItem("informations") || defaultMeta, []);
    const doUpdate = useCallback(({ target }) => update(target), [update]);
    const doUpdateCover = useCallback(
        ({ target }) => {
            if (target.files && target.files[0])
                updateCover(URL.createObjectURL(target.files[0]));
        }, [updateCover]);

    return (
        <React.Fragment>
            <h2 children="presentation or challenge? hmm..." />
            <p>Capture a photo of your finished dish to showcase your recipe. Be sure to include the cooking time, prep time, necessary cookware, and the number of servings for a complete and informative presentation.</p>
            <p>Ooor... Leave these details out and create a fun challenge. If you're not familiar with the concept, learn <a href="https://gustasta.com" target="_blank" rel="noreferrer">what's a challenge</a> from our website.</p>
            <p>Ready? <label htmlFor="file" children="Begin by uploading an image" />!</p>
            <div
                onInput={doUpdate}
                contentEditable="plaintext-only"
                suppressContentEditableWarning={true}
                data-placeholder="...">
                {initialText}
            </div>
            <input type="file" id="file" style={{ display: "none" }} onChange={doUpdateCover} />
        </React.Fragment>
    );
}

const cookingVerbs = {
    "Preparation": [
        "chop",
        "dice",
        "mince",
        "slice",
        "julienne",
        "peel",
        "grate",
        "shred",
        "crush",
        "press",
        "zest",
        "trim",
        "cut",
        "cube",
        "segment",
        "score",
        "debone",
        "fillet",
        "deglaze"
    ],
    "Mixing": [
        "stir",
        "whisk",
        "fold",
        "mix",
        "blend",
        "beat",
        "cream",
        "knead",
        "whip",
        "toss"
    ],
    "Cooking Methods": [
        "bake",
        "roast",
        "boil",
        "simmer",
        "steam",
        "fry",
        "deep-fry",
        "stir-fry",
        "grill",
        "broil",
        "poach",
        "sear",
        "saute",
        "braise",
        "stew",
        "smoke",
        "toast",
        "microwave",
        "slow-cook"
    ],
    "Handling": [
        "pour",
        "drizzle",
        "drain",
        "strain",
        "dip",
        "dredge",
        "coat",
        "marinate",
        "stuff",
        "fill",
        "wrap",
        "roll",
        "spread",
        "layer",
        "skewer"
    ],
    "Heating": [
        "preheat",
        "warm",
        "cool",
        "reheat",
        "melt",
        "char",
        "reduce"
    ],
    "Seasoning & Flavoring": [
        "season",
        "salt",
        "pepper",
        "spice",
        "herb",
        "sweeten",
        "sugar",
        "glaze",
        "brine",
        "pickle",
        "caramelize",
        "baste"
    ],
    "Serving & Presenting": [
        "garnish",
        "plate",
        "arrange",
        "slice",
        "portion",
        "drizzle",
        "serve"
    ],
    "Cleaning": [
        "wash",
        "rinse",
        "wipe",
        "scrub",
        "sanitize",
        "dry"
    ],
    "Fermenting": [
        "ferment",
        "cure",
        "brew"
    ],
    "Cooling & Freezing": [
        "chill",
        "freeze",
        "cool",
        "ice",
        "defrost",
        "thaw"
    ]
};

const ingredients = () => {
    const text = localStorage.getItem("ingredients") || "";
    const items = text.split(/[,\n]/).map(a => a.trim());
    const regex = /^(?<quantity>\d+(?:\.\d+)?[a-z]{0,3})\s+(?<name>.*)$/;

    return items
        .filter(a => a.length > 0)
        .map(a => {
            const matches = a.match(regex);

            if (matches && matches.groups) {
                const { name } = matches.groups;

                return name;
            }

            return a;
        })
        .sort();
};

function InstructionsLibrary({ onInsert, closeWindow }) {
    const [selectedIngredients, setSelectedIngredients] = useState([]);
    const [selectedAction, setSelectedAction] = useState("");
    const [variants, setVariants] = useState([]);

    const updateIngredient = useCallback(
        ({ target }) => setSelectedIngredients([...target.selectedOptions].map(a => a.value)),
        [setSelectedIngredients]);

    const updateAction = useCallback(
        ({ target }) => setSelectedAction(target.value),
        [setSelectedAction]);

    const insertStep = useCallback(
        (a) => {
            onInsert(a);
            closeWindow();
        },
        [onInsert, closeWindow]);

    useEffect(() => {
        const path = [];
        if (selectedAction) path.push("verb=" + selectedAction);
        if (selectedIngredients.length > 0)
            path.push("ingredients=" + selectedIngredients.join("|"));

        async function get(vars) {
            const http = await fetch("library?" + vars.join("&"));

            return await http.json();
        }

        get(path).then(a => setVariants(a));
    }, [selectedIngredients, selectedAction, setVariants]);

    return (
        <aside className="library window">
            <div className="selectors">
                What's the action to instruct?
                <select onChange={updateAction}>
                    <option children="-- nothing --" value="" />
                    {Object.keys(cookingVerbs).map((b, j) =>
                        <optgroup label={b} key={j}>
                            {cookingVerbs[b].map((a, i) => <option key={i} children={a} />)}
                        </optgroup>)}
                </select>
                What's the ingredient?
                <select onChange={updateIngredient} multiple={true} defaultValue={[]} size={10}>
                    {ingredients().map((a, i) => <option key={i} children={a} />)}
                </select>
            </div>
            <div className="results">
                {variants.map(a =>
                    <div key={a.name} onDoubleClick={() => insertStep(a)} className="item">
                        <div className="media">
                            {a.url.endsWith(".mp4")
                                ? <video
                                    muted
                                    autoPlay
                                    loop
                                    children={<source type="video/mp4" src={a.url} />}
                                    width="100%" />
                                : <img src={a.url} alt={a.name} />}
                        </div>
                        <span children={a.name} />
                    </div>)}
            </div>
        </aside>
    );
}

function Instructions({ update }) {
    const contentRef = useRef(null);
    const [libraryWindow, setLibraryWindow] = useState(false);
    const doUpdate = useCallback(({ target }) => update(target), [update]);
    const doInsert = useCallback(
        ({ name }) => {
            if (contentRef.current.textContent !== "")
                contentRef.current.textContent = contentRef.current.textContent.trim() + "\n\n";
            contentRef.current.textContent += name;
            update(contentRef.current);
        }, [update, contentRef]);

    useEffect(() => {
        const close = (e) => e.keyCode === 27 && setLibraryWindow(false);
        window.addEventListener("keydown", close);
        return () => window.removeEventListener("keydown", close);
    }, [])

    const openLibrary = useCallback(() => setLibraryWindow(true), [setLibraryWindow]);
    const prevInstructions = useMemo(() => localStorage.getItem("instructions"), []);

    return (
        <React.Fragment>
            <h2 children="guide the way to a perfect dish" />
            <p>Write simple and concise cooking instructions that are easy to understand. Be sure to separate each instruction with a blank line and keep them in the correct order, as the sequence is important.</p>
            <p>Tap into <a href="#library" onClick={openLibrary}>our library</a> of kitchen actions to help you create a visually engaging and easy-to-follow set of cooking instructions.</p>
            <p>If a step requires timing or temperature, add a note using the format /time or /temperature (e.g., /200C for 200°C or /30m for 30 minutes).</p>
            <div
                ref={contentRef}
                onInput={doUpdate}
                contentEditable="plaintext-only"
                suppressContentEditableWarning={true}
                data-placeholder={"The first line explains what do to\nYou can add a new line for more context to it"}>
                {prevInstructions}
            </div>
            <small children="You can navigate back and forth through the instrustions by clicking on the left/right arrows. Note that the progress bar will never reach 100% because the closing step is self-generated." />
            {libraryWindow &&
                <InstructionsLibrary
                    onInsert={doInsert}
                    closeWindow={() => setLibraryWindow(false)} />}
        </React.Fragment>
    );
}

function Ingredients({ update }) {
    const prevIngredients = useMemo(() => localStorage.getItem("ingredients"), []);
    const doUpdate = useCallback(({ target }) => update(target), [update]);

    return (
        <React.Fragment>
            <h2 children="every name and amount matters" />
            <p>Start adding your ingredients, making sure to include both the name and quantity. You'll see a preview of your list as you go.</p>
            <p>Feel free to enter ingredients in any order, either separated by commas or on new lines. For clarity, use singular nouns and format each entry as quantity/unit <kbd children="[space]" /> ingredient.</p>
            <div
                onInput={doUpdate}
                contentEditable="plaintext-only"
                suppressContentEditableWarning={true}
                data-placeholder="e.g., 200ml milk, 0.5kg potato">
                {prevIngredients}
            </div>
        </React.Fragment>
    );
}
